import ColorToggle from './color-toggle';
import Info from './info';
import InputNumber from './input-number';
import Main from './main';

export default {
  ColorToggle,
  Info,
  InputNumber,
  Main,
};
