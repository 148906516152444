import AlertCircleBold from './alert-circle-bold';
import ColorToggle from './color-toggle';
import Clock from './clock';
import InfoCircleBold from './info-circle-bold';

export default {
  AlertCircleBold,
  ColorToggle,
  Clock,
  InfoCircleBold,
};
